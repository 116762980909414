import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import { Layout } from '../components/layout';
import { PostLink } from '../components/post/post-link';
import { LeftArrow, NavEnd, NavLink, NavLinkContainer, RightArrow } from '../components/nav-link';
import { palette } from '../theme/palette';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';

class NavBar extends React.Component {
    static propTypes = {
        pageContext: PropTypes.object,
    };

    render() {
        const { index, pageCount } = this.props.pageContext;

        const first = index - 1 === 0;
        const last = index === pageCount;

        const previousUrl = this.url('previous');
        const nextUrl = this.url('next');

        return (
            <NavLinkContainer>
                {first ? (
                    <NavEnd align={'center'}>No More Posts</NavEnd>
                ) : (
                    <NavLink to={previousUrl} align={'center'}>
                        <LeftArrow />
                        <span>Newer Posts</span>
                    </NavLink>
                )}

                <div style={{ margin: '1rem', alignSelf: 'center' }}>
                    <span style={{ color: palette.gray }}>{'Page '}</span>
                    <select value={index} style={{ margin: '0 0.25rem' }} onChange={this.onPageSelected}>
                        {new Array(pageCount).fill(0).map((zero, x) => (
                            <option key={x} value={x + 1}>
                                {x + 1}
                            </option>
                        ))}
                    </select>
                    <span style={{ color: palette.gray, marginRight: '0.25rem' }}>{' of '}</span>
                    {pageCount}
                </div>

                {last ? (
                    <NavEnd align={'center'}>No More Posts</NavEnd>
                ) : (
                    <NavLink to={nextUrl} align={'center'}>
                        <span>Older Posts</span>
                        <RightArrow />
                    </NavLink>
                )}
            </NavLinkContainer>
        );
    }

    url(type) {
        const { index, pathPrefix } = this.props.pageContext;

        if (type === 'previous') {
            return index - 1 === 1 ? '' : `${pathPrefix}${index - 1}`;
        } else if (type === 'next') {
            return `${pathPrefix}${index + 1}`;
        }

        const pageIndex = parseInt(type, 10);
        const fragment = pageIndex === 1 ? '' : pageIndex;
        return `/${pathPrefix}${fragment}`;
    }

    onPageSelected = event => {
        const pageIndex = event.target.value;
        navigate(this.url(pageIndex));
    };
}

export default ({ data, pageContext }) => {
    const { group } = pageContext;

    return (
        <Layout>
            <Fragment>
                {group.map(({ node }, index) => {
                    const prevEdge = index < group.length - 1 ? group[index + 1] : null;

                    return (
                        <Fragment key={node.id}>
                            <PostLink node={node} />
                            <DateDifference node={node} previousNode={prevEdge ? prevEdge.node : null} />
                        </Fragment>
                    );
                })}
            </Fragment>

            <NavBar pageContext={pageContext} />
        </Layout>
    );
};

const Marker = styled.div`
    margin: 1rem 0;
    text-align: center;
    position: relative;
    color: ${palette.secondary};
    font-size: 0.75rem;

    & > span {
        z-index: 1;
        position: relative;
        border-bottom: 3px dashed ${palette.lightGray};
        border-top: 3px dashed ${palette.lightGray};
        padding: 0.1rem 0.25rem;
        background: white;
    }

    &:before {
        z-index: 0;
        content: '';
        position: absolute;
        left: 50%;
        top: -1rem;
        bottom: -1rem;
        border: 5px dashed ${palette.lightGray};
    }
`;

const DateDifference = ({ node, previousNode }) => {
    if (previousNode === null) {
        return null;
    }

    const date = dayjs(node.frontmatter.rawDate);
    const prevDate = dayjs(previousNode.frontmatter.rawDate);

    const deltaDays = date.diff(prevDate, 'days');

    return (
        <Marker>
            <span>{formatDeltaDays(deltaDays)}</span>
        </Marker>
    );
};

function formatDeltaDays(delta) {
    const days = Math.abs(delta);

    switch (true) {
        case days === 0:
            return 'No Gap!';

        case days <= 30:
            return `${days} ${days === 1 ? ' day' : ' days'}`;

        case days > 30 && days < 365: {
            const months = Math.floor(
                dayjs()
                    .add(days, 'days')
                    .diff(dayjs(), 'months'),
            );
            return `about ${months} ${months === 1 ? ' month' : ' months'}`;
        }

        case days >= 365: {
            const months = Math.floor(
                dayjs()
                    .add(days, 'days')
                    .diff(dayjs(), 'months'),
            );

            const years = Math.floor(months / 12);
            const remMonths = months % 12;
            const monthsSuffix = remMonths === 1 ? 'month' : 'months';
            const yearsSuffix = years === 1 ? 'year' : 'years';

            return `about ${years} ${yearsSuffix}${remMonths === 0 ? '' : ', ' + remMonths + ' ' + monthsSuffix}`;
        }

        default:
            return `${days} ${days === 1 ? 'day' : 'days'}`;
    }
}
