import React from 'react';
import { Link } from 'gatsby';
import { PublishDate } from '../components';
import { formatReadTime } from '../../utils/time-to-read';
import { Card } from '../card';

export const PostLink = ({ style, node: { timeToRead, frontmatter, fields, excerpt } }) => {
    return (
        <Card style={style}>
            <Link to={fields.path} style={{ textDecoration: 'none' }}>
                <PublishDate>{frontmatter.date}</PublishDate>
                <small style={{ marginLeft: '1rem' }}>{formatReadTime(timeToRead)}</small>
                <h3>{frontmatter.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: excerpt }} />
            </Link>
        </Card>
    );
};
